<template>
  <b-card-code title="" style="margin-top: 50px">
    <b-button class="mb-2" variant="primary" @click="AddCoupon">إضافة قسيمة</b-button>
    <!-- table -->
    <vue-good-table
      :key="tableRenderKey"
      :columns="columns"
      :rows="this.coupons"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength
      }"
    >
      <template slot="table-row" slot-scope="props">
        <!-- Column: companyId -->
        <span v-if="props.column.field === 'price'" class="text-nowrap">
          <span class="text-nowrap">{{ props.row.price }}</span>
        </span>
        <span v-else-if="props.column.field === 'isReviewed'" class="text-nowrap">
          <span v-if="!props.row.isReviewed" class="text-nowrap">
            <b-button variant="danger" style="margin-right: 16px">
              <feather-icon icon="XIcon" size="16" />
            </b-button>
          </span>
          <span v-else class="text-nowrap">
            <b-button variant="success" style="margin-right: 16px"> <feather-icon icon="CheckIcon" size="16" /></b-button
          ></span>
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'" style="">
          <div class="d-flex justify-content-start">
            <ImageModal v-if="props.row.imagePath !== null" :imageUrl="props.row.imagePath"> </ImageModal>
            <AddImageModal
              v-else-if="!props.row.isReviewed && props.row.imagePath == null"
              :paymentChangeId="props.row.id"
              :type="'coupon'"
              @render-table="GetCoupons"
            >
            </AddImageModal>

            <button v-if="!props.row.isReviewed" style="margin-right: 16px" class="btn btn-primary" @click="EditCoupon(props.row.id)">
              <feather-icon icon="EditIcon" size="12" />
            </button>
          </div>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> عرض الصفحة الأولى </span>

            <b-form-select v-model="pageLength" :options="['3', '7', '10', '15', '20']" class="mx-1" @change="onPerPageChange" />
            <span class="text-nowrap"> من {{ totalCount }} العناصر </span>
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              class="mt-1 mb-0"
              @input="handlePageChange"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <template #code>
      {{ codeBasic }}
    </template>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BButton,
  BAvatar,
  BBadge,
  BModal,
  VBModal,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BFormDatepicker
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import { codeBasic } from '../code'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import UploudVueFile from '@/views/Invoices/VueFileUploud.vue'
import ImageModal from '@core/components/ImageModal.vue'
import AddImageModal from '@core/components/AddImageModal.vue'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BButton,
    BModal,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormDatepicker,
    ImageModal,
    AddImageModal
  },

  directives: {
    'b-modal': VBModal,
    Ripple
  },
  created() {
    this.GetCoupons()
  },

  data() {
    return {
      coupons: [],
      pageLength: 7,
      currentPage: 1,
      totalCount: 0, // Total rows/items count from API
      totalPages: 0, // Total pages count from API
      dir: false,
      codeBasic,
      columns: [
        {
          label: 'السعر',
          field: 'price'
        },
        {
          label: 'رقم القسيمة',
          field: 'couponNumber'
        },
        {
          label: 'رقم الفاتورة',
          field: 'billNumber'
        },
        {
          label: 'رقم البوليصه',
          field: 'policyNumber'
        },
        {
          label: 'رقم الشهادة',
          field: 'certificateNumber'
        },
        {
          label: 'الشركة',
          field: 'companyName'
        },
        {
          label: 'التاريخ',
          field: 'date'
        },
        {
          label: 'البيان',
          field: 'details'
        },
        {
          label: 'حالة المراجعة',
          field: 'isReviewed'
        },
        {
          label: 'التفاصيل',
          field: 'action',
          width: '27%'
        }
      ],
      searchTerm: '',
      tableRenderKey: 0
    }
  },
  methods: {
    async onPerPageChange(newPerPage) {
      // Update pageLength and refresh data for the current page
      this.pageLength = parseInt(newPerPage, 10)
      await this.GetCoupons()
    },
    async handlePageChange() {
      // Fetch data for the current page
      await this.GetCoupons()
    },
    async renderTable() {
      await this.GetCoupons()
    },
    async GetCoupons() {
      let userId = this.$store.getters.ExtractAcessToken.userId
      const payload = {
        agentId: userId,
        page: this.currentPage,
        pageSize: this.pageLength
      }
      try {
        const response = await this.$store.dispatch('GetAgentCoupons', payload)
        if ((response.status = 200)) {
          this.coupons = response.data.result
          this.tableRenderKey++
          this.totalCount = response.data.totalCountData
          this.totalPages = response.data.pagesCount
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'فشل الاتصال بالسيرفر يرجى المحاولة لاحقا',
            icon: 'TrashIcon',
            variant: 'danger'
          }
        })
      }
    },
    AddCoupon() {
      this.$router.push({ name: 'add-coupon' })
    },
    EditCoupon(id) {
      this.$router.push({
        name: 'add-coupon',
        params: { id: id }
      })
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    }
  }
}
</script>

<style lang="scss" scoped></style>
